import React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from "styled-components"

const Root = styled.div`
  text-align: center;
  margin: 2rem;
`
const BtnAnimation = keyframes`
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
`
const Btn = styled.button`
  padding-left: 55px;
  padding-right: 55px;
  padding-top: 18px;
  padding-bottom: 18px;
  font-size: 26px;
  line-height: 34px;
  height: 72px;
  color: #fff;
  outline: 0;
  position: relative;
  border: none;
  box-shadow: 0 2px 4px 0 rgba(14, 30, 37, 0.12);
  border-radius: 4px;
  background: linear-gradient(to right, #608af6 , #eb89dae8);
  background-size: 400% 400%;
  transistion: 2s bottom;

  &:hover {
    bottom: 2px;
    color: #fff;
    animation: ${BtnAnimation} 5s ease infinite;
  }
`
const CreditCard = styled.div`
  font-size: 14px;
  padding: 5px;
  color: #666;
`

export class SignupButton extends React.Component {
  static propTypes = {
    subtitle: PropTypes.string,
  }

  static defaultProps = {
    subtitle: 'Free 15 day trial. No credit card required.',
  }

  handleClick = (evt) => {
    window.location = evt.target.getAttribute('href')
  }

  render() {
    return (
      <Root>
        <Btn
          onClick={this.handleClick}
          href="https://app.suggested.co/register"
        >
          Get started
        </Btn>
        <CreditCard>{this.props.subtitle}</CreditCard>
      </Root>
    )
  }
}

export default SignupButton
