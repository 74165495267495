import React from 'react'
import SignupButton from '../../components/SignupButton'
import Layout from '../../layouts/index'
import styles from './shared.module.css'

import Chrome from '../../components/Chrome'

import roadmapImage from '../../../assets/roadmap-portal-large.png'
import statusSelectImage from '../../../assets/learn/roadmap-status-select.png'

const LearnRoadmapPage = () => (
  <Layout>
    <div className="page">
      <div className={styles.hero}>
        <h1>Let your customers know what you're working on</h1>
        <div className={styles.subtitle}>
          <p>Don't keep your customers in the dark with product development.</p>
        </div>
        <Chrome>
          <img
            className={styles.heroImage}
            src={roadmapImage}
            alt="screenshot of roadmap"
          />
        </Chrome>
      </div>

      <div className={styles.feature}>
        <div className={styles.description}>
          <h3>What's planned, being worked on, and complete</h3>
          <p>
            For every piece of feedback, you can update the current status of it
            to reflect where it is in your product development process
          </p>
          <p>
            When it is marked as: Planned, In Progress, or Complete, it'll
            appear in your public roadmap.
          </p>
          <p>
            This makes it easy for your customers to know when their feature
            requests, ideas, or bug reports are being worked on.
          </p>
          <p>
            You're customers will love that your product development is
            transparent by default.
          </p>
        </div>
        <div className={styles.image}>
          <img
            className={styles.statusSelectImage}
            src={statusSelectImage}
            alt="feedback with votes by customers"
          />
        </div>
      </div>

      <div className="signup-container">
        <SignupButton />
      </div>
    </div>
  </Layout>
)

export default LearnRoadmapPage
